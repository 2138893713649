import {Box, Chip, FormControl, Input, InputAdornment, MenuItem, Select, Typography, useTheme} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";


export const Header = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            pb: 1,
            color: '#f0f1f1',
        }}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 2,}}>
                <Chip label="Все" sx={{mr: 2, backgroundColor: '#f0f1f150'}}/>
                <Chip label="VQE" color="primary"/>
                <Chip label="Активные" sx={{backgroundColor: '#f0f1f150'}}/>
                <Chip label="В разработке" sx={{backgroundColor: '#f0f1f150'}}/>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
            }}>
                <NavigateBeforeIcon/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Select
                        value={30}
                        label="Месяц"
                        variant="standard"
                        sx={{
                            ...theme.typography.subtitle1,
                            color: '#f0f1f1',
                            '.MuiSvgIcon-root': {
                                color: '#f0f1f1',
                            },
                            '&:before': {
                                display: 'none'
                            },
                        }}
                    >
                        <MenuItem value={10}>Сентябрь</MenuItem>
                        <MenuItem value={20}>Октябрь</MenuItem>
                        <MenuItem value={30}>Ноябрь</MenuItem>
                        <MenuItem value={40}>Декабрь</MenuItem>
                    </Select>
                    <Typography fontWeight="bold" sx={{
                        color: '#f0f1f170'
                    }}>
                        2024
                    </Typography>
                </Box>
                <NavigateNextIcon/>
            </Box>
            <FormControl variant="standard" sx={{
                '.MuiInputBase-root:before': {
                    borderColor: '#f0f1f1',
                },
                '.MuiSvgIcon-root': {
                    color: '#f0f1f170',
                },
            }}>
                <Input
                    id="algorithm-molecule-search"
                    endAdornment={<InputAdornment position="end"><SearchIcon fontSize="small"/></InputAdornment>}
                />
            </FormControl>
        </Box>
    );
};
