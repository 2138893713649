import {styled} from "@mui/material";

export const LinkStyle = styled('div')(() => ({
    position: 'relative',
    display: 'block',

    '.hover': {
        position: 'relative',

        '&:after': {
            content: '" "',
            position: 'absolute',
            bottom: '3px',
            right: 0,
            width: '100%',
            height: '1px',
            backgroundColor: '#081225',
            transform: 'scaleX(0)',
            transformOrigin: 'bottom right',

            transition: 'transform 0.3s',
        }
    },

    '&:hover': {
        '&:before, &:after': {
            transform: 'scaleY(1)',
        },

        '.hover': {
            backgroundColor: 'transparent',

            '&:before, &:after': {
                transform: 'scaleX(1)',
            }
        }
    }
}))