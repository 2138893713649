import {Backdrop, Box, Divider, Slide, Tooltip, Typography, useTheme} from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {Masonry} from "@mui/lab";
import React from "react";
import {DayBackdropCardStyle, DayBackdropCardTitleStyled} from "./style/DayBackdropStyle";
import {useDispatch, useSelector} from "react-redux";
import {algUuidOpenSelector} from "../model/selectors";
import {closeAlgBackdrop} from "../model/slice";

const xxx = {
    "task_id": "a873adfb-77a0-4ee0-bc4c-3b54d3982ec4",
    "task_status": "SUCCESS",
    "task_result": {
        "steps": 3,
        "energy": "-1.13726264",
        "circuit_draw": "0: \u2500\u2500X\u2500\u256dG\u00b2\u2500\u256dG\u00b2\u2500\u2524 \u256d<\ud835\udcd7>\n1: \u2500\u2500X\u2500\u251cG\u00b2\u2500\u251cG\u00b2\u2500\u2524 \u251c<\ud835\udcd7>\n2: \u2500\u2500\u2500\u2500\u251cG\u00b2\u2500\u251cG\u00b2\u2500\u2524 \u251c<\ud835\udcd7>\n3: \u2500\u2500\u2500\u2500\u2570G\u00b2\u2500\u2570G\u00b2\u2500\u2524 \u2570<\ud835\udcd7>"
    }
}

export const DayBackdrop = () => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const uuid = useSelector(algUuidOpenSelector);

    const handleClose = () => {
        dispatch(closeAlgBackdrop())
    }

    return (
        <Backdrop
            unmountOnExit
            sx={{zIndex: theme.zIndex.drawer + 1}}
            open={!!uuid}
            onClick={handleClose}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '1200px', margin: '0 auto', width: '100%'}}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ExpandCircleDownIcon sx={{ mb: 5, transform: 'rotate(180deg)', color: '#f0f1f1' }} fontSize="large" />
                </Box>
                <Masonry columns={2} spacing={3}>
                    <Slide direction="down" in mountOnEnter unmountOnExit>
                        <DayBackdropCardStyle color="primary">
                            <DayBackdropCardTitleStyled>Результаты расчета</DayBackdropCardTitleStyled>
                            <Box sx={{px: 3,}}>
                                <Typography variant="h3">
                                    -1.332...
                                </Typography>
                                <Typography variant="body2">
                                    Энергия основного состояния
                                </Typography>
                            </Box>
                            <Divider variant="fullWidth" sx={{ borderColor: '#f0f1f170', my: 3 }} />
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2,}}>
                                {[
                                    ['Молекула', 'H2O'],
                                    ['Базис', 'STO-3G'],
                                    ['Требуемая точность', '1e-7'],
                                    ['Кол-во активных электронов', '4'],
                                    ['Кол-во активных орбиталей', '4']
                                ].map(([name, value]) => (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 3 }} key={name}>
                                        <Typography variant="subtitle1">{name}</Typography>
                                        <Typography variant="h6" fontWeight="bold">{value}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </DayBackdropCardStyle>
                    </Slide>
                    <Slide direction="left" in mountOnEnter unmountOnExit>
                        <DayBackdropCardStyle color="white">
                            <DayBackdropCardTitleStyled>Схема алгоритма</DayBackdropCardTitleStyled>
                            <Box sx={{
                                maxHeight: 600,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                justifyContent: 'flex-end'
                            }}>
                                <Box component="pre" sx={{fontSize: theme.typography.h6.fontSize, m: 0}}>
                                    {xxx.task_result.circuit_draw}
                                </Box>
                                <Box component="pre" sx={{fontSize: theme.typography.h6.fontSize, m: 0}}>
                                    {xxx.task_result.circuit_draw}
                                </Box>
                                <Box component="pre" sx={{fontSize: theme.typography.h6.fontSize, m: 0}}>
                                    {xxx.task_result.circuit_draw}
                                </Box>
                                <Box component="pre" sx={{fontSize: theme.typography.h6.fontSize, m: 0}}>
                                    {xxx.task_result.circuit_draw}
                                </Box>
                            </Box>
                        </DayBackdropCardStyle>
                    </Slide>
                    <Slide direction="up" in mountOnEnter unmountOnExit>
                        <DayBackdropCardStyle color="white">
                            <Box sx={{
                                display: 'flex',
                            }}>
                                <Box sx={{
                                    borderRight: `1px solid ${theme.palette.grey["500"]}`,
                                    pr: 3,
                                    mr: 3,
                                }}>
                                    <Typography variant="caption">Время выполнения</Typography>
                                    <Typography variant="h4">1ч 27м</Typography>
                                </Box>
                                <Box sx={{display: 'flex', flexGrow: 1, gap: 1}}>
                                    {[21, 79].map((value, index) => (
                                        <Box key={value}
                                             sx={{width: `${value}%`, display: 'flex', flexDirection: 'column'}}>
                                            <Box sx={{display: 'flex', gap: 1}}>
                                                <Box sx={{
                                                    borderLeft: `1px dashed ${theme.palette.grey["500"]}`,
                                                    height: '40px'
                                                }}></Box>
                                                <Typography variant="caption">{value}%</Typography>
                                            </Box>
                                            <Box sx={{
                                                backgroundColor: index ? '#e5eeff' : '#ffefe7',
                                                borderRadius: 3,
                                                height: '12px',
                                                width: '100%',
                                                border: `1px solid ${theme.palette.grey["400"]}`
                                            }}>&nbsp;</Box>

                                            <Tooltip title={index ? 'Выполнение алгоритма' : 'Подготовка'}>
                                                <Typography variant="caption" sx={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap'
                                                }}>
                                                    {index ? 'Выполнение алгоритма' : 'Подготовка'}
                                                </Typography>
                                            </Tooltip>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </DayBackdropCardStyle>
                    </Slide>
                </Masonry>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', color: '#f0f1f1' }}>
                    <Typography variant="subtitle1">
                        Следующий расчет
                    </Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Моделирование химических реакций <br/>
                        09:27
                    </Typography>
                    <ExpandCircleDownIcon sx={{ mt: 1 }} fontSize="large" />
                </Box>
            </Box>
        </Backdrop>
    );
};
