import {Box, FormControl, FormHelperText, Input, InputAdornment, Typography, useTheme} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, {ChangeEvent, useState} from "react";
import {Control, Controller} from "react-hook-form";
import {PrepareAlgDialogFormState} from "../../model/prepare-algorithm";
import SearchOffIcon from '@mui/icons-material/SearchOff';

interface Props {
    control: Control<PrepareAlgDialogFormState>;
}

export const MolNameFormParam = ({control}: Props) => {
    const theme = useTheme();

    const [searchValue, setSearchValue] = useState("");

    const availableMolList = ['H2O', 'C2', 'LiH', 'H2'];
    const molList = searchValue
        ? availableMolList.filter(mol => mol.toLowerCase().includes(searchValue.toLowerCase()))
        : availableMolList;

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }

    return (
        <>
            <FormControl variant="standard" fullWidth sx={{mb: 1}}>
                <Input
                    endAdornment={<InputAdornment position="end"><SearchIcon fontSize="small"/></InputAdornment>}
                    onChange={handleSearchChange}
                />
                <FormHelperText>Поиск</FormHelperText>
            </FormControl>

            <Controller
                name="molName"
                control={control}
                render={({field}) => (
                    <Box sx={{
                        display: 'grid',
                        gap: 3,
                        [theme.breakpoints.up('md')]: {
                            gridAutoColumns: 'minmax(0, 1fr)',
                            gridAutoFlow: 'column',
                        },
                        [theme.breakpoints.down('md')]: {
                            gridTemplateColumns: '1fr',
                            gap: 1,
                        },
                    }}>
                        {molList.length && molList.map((v) => (
                            <Box
                                key={v}
                                onClick={() => field.onChange(v)}
                                sx={{
                                    flexGrow: 1,
                                    width: '100%',
                                    borderRadius: 4,
                                    backgroundColor: theme.palette.grey["200"],
                                    p: 4,
                                    mb: 1,
                                    border: field.value === v
                                        ? `2px solid ${theme.palette.primary.main}`
                                        : `2px solid ${theme.palette.grey["300"]}`,
                                    color: field.value === v
                                        ? theme.palette.primary.main
                                        : theme.palette.grey["700"],
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Alfa Slab One',
                                    cursor: 'pointer',
                                    fontSize: theme.typography.h6.fontSize,
                                    [theme.breakpoints.down('lg')]: {
                                        fontSize: theme.typography.subtitle1.fontSize,
                                    }
                                }}>
                                {v}
                            </Box>
                        )) || <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gridColumnStart: 1,
                            gridColumnEnd: 5,
                            color: theme.palette.grey["500"]
                        }}>
                            <SearchOffIcon fontSize="large"/>
                            <Typography fontWeight="bold">Ничего не найдено</Typography>
                        </Box>}
                    </Box>
                )}
            />
        </>
    )
};

