import {ThemeOptions} from "@mui/material";

export const baseThemeOptions: ThemeOptions = {
    typography: {
        fontFamily: "Ibm Plex Sans",
        fontSize: 14,
    },
    palette: {
        background: {
            default: '#646b73',
            paper: '#f4ebe5'
        },
        primary: {
            light: '#fae0c1',
            main: '#081225'
        },
        // success: {
        //     light: '#bff0db',
        //     main: '#63BE97',
        // },
        // info: {
        //     main: '#d6d2ff',
        //     dark: '#AAA2FF',
        // }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: ({theme}) => ({
                    '.MuiPaper-rounded': {
                        backgroundColor: theme.palette.grey["100"],
                        borderRadius: theme.spacing(4),
                        border: `8px solid ${theme.palette.common.white}`
                    }
                })
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({theme}) => ({
                    padding: theme.spacing(2)
                })
            }
        },
        MuiTypography: {
            variants: [
                {
                    props: {variant: 'body1'},
                    style: ({theme}) => ({
                        fontSize: '0.875rem'
                    })
                }
            ]
        },
        MuiSelect: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: theme.spacing(3)
                    }
                }),
            },
            variants: [
                {
                    props: { color: undefined },
                    style: ({ theme }) => ({
                        color: '#f0f1f190',
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#f0f1f190'
                        },
                        '.MuiSelect-icon': {
                            color: '#f0f1f190'
                        }
                    })
                }
            ]
        },
        MuiTab: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: theme.spacing(3),
                    padding: theme.spacing(3),
                    color: '#f0f1f1',
                    '&.Mui-selected': {
                        backgroundColor: '#081225',
                        color: '#f0f1f1',
                    },
                    [theme.breakpoints.down('md')]: {
                        borderRadius: theme.spacing(2),
                        padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                    }
                })
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    display: 'none',
                },
                scrollButtons: {
                    color: '#ffefe7',
                    '&.Mui-disabled': {
                        display: 'none',
                    }
                },
                root: ({theme}) => ({
                    [theme.breakpoints.down('md')]: {
                        maxWidth: 'calc(100vw - 70px)',
                    },
                })
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: 'rgba(8, 18, 37, 0.08)',
                    padding: theme.spacing(.5),
                    borderRadius: theme.spacing(3),
                    '.MuiToggleButton-root': {
                        border: 'none',
                        borderRadius: theme.spacing(3),
                        minWidth: 100,
                    },
                    '.MuiToggleButton-root.Mui-selected': {
                        backgroundColor: '#f0f1f1',
                    }
                })
            }
        }
    }
}