import {Box, useTheme} from "@mui/material";
import {CardButton, CardStyled, CardTitleStyled} from "shared/ui/card";
import {
    WidgetChemistryAlgorithmVqeAdaptiveHistory,
    WidgetChemistryAlgorithmVqeAdaptivePrepareAlgorithm
} from "widgets/chemistry-algorithm-vqe-adaptive";
import React from "react";

export const VqeAdaptiveTabPanel = () => {
    const theme = useTheme();
    return (
        <Box sx={{
            display: 'grid',
            // gridTemplateAreas: "'prepare history' 'prepare history-btn'",
            // gridTemplateColumns: '2fr 1fr',
            // gridTemplateColumns: {
            //     xl: '2fr 1fr',
            //     lg: '4fr 3fr',
            //     md: '1fr 1fr',
            // },
            // gridTemplateRows: {
            //     xl: '1fr minmax(auto, 75px)',
            //     lg: '1fr minmax(auto, 75px)',
            //     md: 'auto'
            // },
            //
            gridTemplateColumns: '60% 40%',
            gridTemplateAreas: "'prepare history' 'prepare history-btn'",
            gridTemplateRows: '1fr minmax(auto, 75px)',
            [theme.breakpoints.down('xl')]: {
                gridTemplateColumns: '57% 43%',
                gridTemplateAreas: "'prepare history' 'prepare history-btn'",
            },
            [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: '50% 50%',
                gridTemplateAreas: "'prepare history' 'prepare history-btn'",
            },
            [theme.breakpoints.down('md')]: {
                gridTemplateColumns: 'minmax(min-content, 1fr)',
                gridTemplateAreas: "'prepare' 'history' 'history-btn'",
                gridTemplateRows: 'auto',
            },
            gap: 3,
        }}>
            <Box sx={{gridArea: 'prepare'}}>
                <CardStyled sx={{
                    backgroundColor: '#f0f1f1',
                    height: '100%',
                }}>
                    <CardTitleStyled sx={{mb: 3}}>
                        Подготовка расчета VQE
                    </CardTitleStyled>
                    <WidgetChemistryAlgorithmVqeAdaptivePrepareAlgorithm/>
                </CardStyled>
            </Box>
            <Box sx={{gridArea: 'history '}}>
                <WidgetChemistryAlgorithmVqeAdaptiveHistory/>
            </Box>
            <Box sx={{gridArea: 'history-btn'}}>
                <CardStyled sx={{
                    backgroundColor: '#ffefe7',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CardButton name="История запусков" onClick={() => {
                    }}/>
                </CardStyled>
            </Box>
        </Box>
    );
};
