import React from "react";
import {Box, useMediaQuery, useTheme} from "@mui/material";
import {WidgetDocumentationOverview} from "widgets/documentation-overview";
import {WidgetExecutionQueue} from "widgets/execution-queue";
import {Header} from "./Header";
import {AlgorithmTab} from "./algorithm-tab/AlgorithmTab";
import {ExecutionQueueDrawer} from "pages/main/ui/ExecutionQueueDrawer";


export const Page = () => {
    const theme = useTheme();
    const media = useMediaQuery(`(max-width: ${theme.breakpoints.values.lg}px)`);

    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: '70% 30%',
            [theme.breakpoints.down('lg')]: {
                gridTemplateColumns: '1fr',
                mb: '56px',
            },
        }}>
            <Box sx={{

            }}>
                <Header/>
                <AlgorithmTab/>
            </Box>
            <Box sx={{
                pl: 3,
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                }
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    p: 3,
                    pr: 0,
                    position: 'sticky',
                    top: 0,
                    borderLeft: `1px solid #f0f1f170`,
                }}>
                    <Box sx={{height: '600px'}}>
                        <WidgetExecutionQueue/>
                    </Box>
                    <WidgetDocumentationOverview/>
                </Box>
            </Box>

            {media && (
                <ExecutionQueueDrawer />
            )}
        </Box>
    );
};
