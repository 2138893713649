import {Box} from "@mui/material";
import React, {MouseEvent} from "react";
import {CalendarDayNumberStyle, CalendarDayStyle, CalendarWeekStyle} from "./style/CalendarStyle";
import {CalendarDayAlgList} from "./CalendarDayAlgList";
import {useDispatch} from "react-redux";
import {openDayList} from "../model/slice";

export const Calendar = () => {
    const dispatch = useDispatch();

    const handleDayClick = (e: MouseEvent<HTMLElement>) => {
        const { bottom, top, width, height, x, y, left, right} = e.currentTarget.getBoundingClientRect();
        const day = e.currentTarget.dataset['day'];
        if (day) {
            dispatch(openDayList({
                day: +day,
                rect: { bottom, top, width, height, x, y, left, right} as DOMRect,
            }))
        }
    }

    return (
        <Box sx={{
            flexGrow: 1,
            display: 'grid',
            gridTemplateColumns: 'repeat(7, calc(100% / 7))',
            gridTemplateRows: 'auto repeat(5, 1fr)'
        }}>
            <CalendarWeekStyle>Пн</CalendarWeekStyle>
            <CalendarWeekStyle>Вт</CalendarWeekStyle>
            <CalendarWeekStyle>Ср</CalendarWeekStyle>
            <CalendarWeekStyle>Чт</CalendarWeekStyle>
            <CalendarWeekStyle>Пт</CalendarWeekStyle>
            <CalendarWeekStyle>Сб</CalendarWeekStyle>
            <CalendarWeekStyle>Вс</CalendarWeekStyle>

            {[28, 29, 30, 31].map((day) => (
                <CalendarDayStyle disabled key={day}>
                    <CalendarDayNumberStyle>
                        {day}
                    </CalendarDayNumberStyle>
                </CalendarDayStyle>
            ))}
            {[...Array(30).keys()].map((day) => (
                <CalendarDayStyle key={day + 1} data-day={day + 1} onClick={handleDayClick}>
                    <CalendarDayNumberStyle>
                        {day + 1}
                    </CalendarDayNumberStyle>
                    <CalendarDayAlgList day={day + 1} />
                </CalendarDayStyle>
            ))}

            {[1].map((day) => (
                <CalendarDayStyle disabled key={day}>
                    <CalendarDayNumberStyle>
                        {day}
                    </CalendarDayNumberStyle>
                </CalendarDayStyle>
            ))}
        </Box>
    );
};
