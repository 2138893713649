import React from "react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import {LinkStyle} from "../common/link";
import {CardButtonStyled} from "./style/CardStyle";

interface Props {
    name: string;
    onClick: () => void;
}

export const CardButton = ({name, onClick}: Props) => (
    <LinkStyle onClick={onClick}>
        <CardButtonStyled sx={{p: 0}} variant="text" endIcon={<ArrowRightAltIcon/>} className="hover">
            {name}
        </CardButtonStyled>
    </LinkStyle>
)
