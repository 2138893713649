export enum QuantumChemistryAlgorithmResultStatus {
    error = "FAILURE",
    success = "SUCCESS",
    waiting = "waiting",
    running = "running",
}

export enum QuantumChemistryAlgorithmType {
    vqe = "vqe",
    molGeo = "molGeo",
    reactions = "reactions",
}

interface VqeParams {
    molName: string;
    basis: string;
    activeElectrons: number;
    activeOrbitals: number;
    accuracy: number;
}

export interface RunQuantumChemistryAlgorithmRequest {
    type: QuantumChemistryAlgorithmType.vqe;
    params: VqeParams
}

export interface RunQuantumChemistryAlgorithmResponse {
    uuid: string;
}

export interface QuantumChemistryAlgorithmListRequest {
    type: QuantumChemistryAlgorithmType;
    status: 'complete' | 'running' | 'waiting' | Array<'complete' | 'running' | 'waiting'>;
    size?: number;
}

export type QuantumChemistryAlgorithmListResponse = Array<{
    status: QuantumChemistryAlgorithmResultStatus;
    date: Date;
    uuid: string;
} & ({
    type: QuantumChemistryAlgorithmType.vqe;
    params: VqeParams;
    result: {
        energy: {
            value: string;
            unit: string;
        },
        quantumScheme: string;
        quantumSchemeFullLog: string;
    };
    error: null;
} | {
    type: QuantumChemistryAlgorithmType.vqe;
    params: VqeParams;
    result: null;
    error: string;
})>