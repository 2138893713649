export enum AlgorithmType {
    vqe = "vqe",
    molGeo = "molGeo",
    reactions = "reactions",
}

export interface AlgorithmDayItem {
    uuid: string;
    type: AlgorithmType;
    timeFinished: Date;
}