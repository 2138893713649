import {RoutesPath} from "app/routes/const/routesPath";
import {NavLink} from "react-router";
import {MenuLinkDotStyled, MenuLinkStyled} from "app/routes/ui/style/MenuStyle";
import classNames from "classnames";
import {Box, Typography, useTheme} from "@mui/material";
import React from "react";
import {LayoutWrapperStyle} from "app/routes/ui/style/LayoutStyle";
import AppNotification from "app/routes/ui/AppNotification";
import {AppLogo} from "app/routes/ui/AppLogo";

export const AppDesktopMenu = () => {
    const theme = useTheme();

    return (
        <LayoutWrapperStyle
            sx={{
                display: 'flex',
                color: '#f0f1f1',
                gap: 3,
                [theme.breakpoints.up(2000)]: {
                    maxWidth: '2000px',
                    margin: '0 auto',
                }
            }}
        >
            <AppLogo/>
            <Box
                sx={{
                    display: 'flex',
                    ml: '200px',
                    gap: 5,
                    [theme.breakpoints.down('lg')]: {
                        ml: '50px',
                        gap: 3,
                    },
                    flexGrow: 1,
                }}>
                {[
                    {path: RoutesPath.chemistry, name: 'Алгоритмы'},
                    {path: RoutesPath.chemistryHistory, name: 'История расчетов'},
                    {path: RoutesPath.doc, name: 'Документация'}
                ].map(({path, name}) => (
                    <NavLink key={path} to={path} end style={{textDecoration: 'none'}}>
                        {({isActive}) => (
                            <MenuLinkStyled className={classNames({'active': isActive})}>
                                {name}
                                {isActive && <MenuLinkDotStyled/>}
                            </MenuLinkStyled>
                        )}

                    </NavLink>
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 3,
                    pb: 2,
                }}>
                <AppNotification/>
                <Box sx={{
                    display: 'flex',
                    borderRadius: 6,
                    backgroundColor: '#6f777f',

                    py: .5,
                    px: 1,
                    width: '200px',
                    gap: 1,
                }}>
                    <Box sx={{
                        borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <img src="/avatar.jpg" width="32px" height="32px" style={{borderRadius: '100%'}}/>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Typography variant="caption" fontWeight="bold">Гущин Яков</Typography>
                        <Typography variant="caption">Отец-основатель</Typography>
                    </Box>
                </Box>
            </Box>
        </LayoutWrapperStyle>
    );
};

