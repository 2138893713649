import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {
    Box,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Radio,
    RadioGroup,
    Slider,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {MolNameFormParam} from "./MolNameFormParam";
import {PrepareAlgDialogFormState} from "../../model/prepare-algorithm";
import {useRunAlgorithmMutation} from "shared/api";
import {AccuracyList, BasisList, MolList} from "../../model/prepare-const";
import {LoadingButton} from "@mui/lab";
import {useSnackbar} from "notistack";
import {QuantumChemistryAlgorithmType} from "shared/models";

export const WidgetPrepareAlgorithm = () => {
    const {enqueueSnackbar} = useSnackbar();

    const [runAlgorithm, {isLoading}] = useRunAlgorithmMutation();


    const defaultValues: PrepareAlgDialogFormState = {
        molName: MolList[0],
        basis: BasisList[0].value,
        activeElectrons: null,
        activeOrbitals: null,
        accuracy: Math.min(...AccuracyList),
    }

    const {handleSubmit, reset, control, formState} = useForm({
        defaultValues,
        mode: "onChange"
    });

    const onSubmit: SubmitHandler<PrepareAlgDialogFormState> = (data) => {
        const { activeElectrons, activeOrbitals, accuracy, ...restData} = data;
        if (!activeOrbitals || !activeElectrons) {
            enqueueSnackbar(`Укажите количество активных электронов и орбиталей`, { variant: 'error' })
            return
        }
        runAlgorithm({
            type: QuantumChemistryAlgorithmType.vqe,
            params: {
                ...restData,
                activeOrbitals,
                activeElectrons,
                accuracy: 1 / (10 ** accuracy),
            },
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar(`VQE алгоритм для молекулы ${restData.molName} запущен`, { variant: 'success' })
            })
            .catch((e) => {
                enqueueSnackbar(`Ошибка при запуске алгоритма ${JSON.stringify(e)}`, { variant: 'error'})
            })
    }

    return (
        <Box sx={{ height: '100%'}}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 1}}>
                <Chip label="1"/>
                <Typography>Выберите молекулу</Typography>
            </Box>
            <Box sx={{px: 1}}>
                <MolNameFormParam control={control}/>
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 1, mt: 3}}>
                <Chip label="2"/>
                <Typography>Выберите базис</Typography>
            </Box>
            <Box sx={{px: 1}}>
                <Controller
                    name="basis"
                    control={control}
                    render={({field}) => (
                        <RadioGroup row {...field}>
                            {BasisList.map(({value, label, disabled}) =>
                                disabled === true
                                    ? (
                                        <Tooltip title="Базис не доступен для выбранной молекулы" arrow key={value}>
                                            <FormControlLabel
                                                disabled
                                                label={label}
                                                value={value}
                                                control={<Radio/>}
                                            />
                                        </Tooltip>
                                    )
                                    : <FormControlLabel
                                        key={value}
                                        label={label}
                                        value={value}
                                        control={<Radio/>}
                                    />
                            )}
                        </RadioGroup>
                    )}
                />
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mt: 3}}>
                <Chip label="3"/>
                <Typography>Укажите количество активных электронов и орбиталей</Typography>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 5, px: 1}}>
                <Controller
                    name="activeElectrons"
                    control={control}
                    rules={{
                        required: true,
                        min: {
                            value: 1,
                            message: 'не менее одного'
                        },
                        max: {
                            value: 6,
                            message: 'не более шести'
                        },
                    }}
                    render={({field, fieldState}) => (
                        <FormControl variant="standard" fullWidth {...field} error={fieldState.invalid}>
                            <TextField variant="standard" type="number" error={fieldState.invalid}/>
                            <FormHelperText>Кол-во электронов {fieldState.error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
                <Controller
                    name="activeOrbitals"
                    control={control}
                    rules={{
                        required: true,
                        min: {
                            value: 1,
                            message: 'не менее одного'
                        },
                        max: {
                            value: 6,
                            message: 'не более шести'
                        },
                    }}
                    render={({field, fieldState}) => (
                        <FormControl variant="standard" fullWidth {...field} error={fieldState.invalid}>
                            <TextField variant="standard" type="number" error={fieldState.invalid}/>
                            <FormHelperText>Кол-во орбиталей {fieldState.error?.message}</FormHelperText>
                        </FormControl>
                    )}
                />
            </Box>

            <Box sx={{display: 'flex', alignItems: 'center', gap: 1, mb: 2, mt: 3}}>
                <Chip label="4"/>
                <Typography>Укажите точность</Typography>
            </Box>
            <Box sx={{display: 'grid', gridTemplateColumns: { xl: '1fr 1fr', lg: '1fr', md: '1fr'}, gap: 5, px: 2}}>
                <Controller
                    name="accuracy"
                    control={control}
                    render={({field}) => (
                        <Slider
                            step={null}
                            valueLabelDisplay="off"
                            marks={[10, 9, 8, 7, 6, 5, 4, 3].map(v => ({
                                value: v,
                                label: (1 / (10 ** v)).toExponential(0)
                            }))}
                            min={3}
                            max={10}
                            scale={value => 1 / (10 ** value)}
                            onChange={field.onChange}
                            value={field.value || 3}
                        />
                    )}
                />
            </Box>
            <Box sx={{display: 'flex', mt: 3, justifyContent: 'flex-end'}}>
                <LoadingButton
                    loading={isLoading}
                    disabled={!formState.isValid}
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                >
                    Отправить
                </LoadingButton>
            </Box>
        </Box>
    );
};
