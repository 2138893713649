import {Box, styled} from "@mui/material";

export const MenuLinkStyled = styled('div')(({theme}) => ({
    ...theme.typography.body1,
    color: '#f0f1f1',
    position: 'relative',
    textDecoration: 'none',
    height: '100%',

    '&.active': {
        '&:before': {
            content: '" "',
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '5px solid #f0f1f170',
            position: 'absolute',
            bottom: '0',
            left: '50%',
            zIndex: 1,
            transform: 'translateX(-50%)',
        },

        '&:after': {
            content: '" "',
            width: 0,
            height: 0,
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderBottom: '5px solid #646b73',
            position: 'absolute',
            bottom: '-1px',
            left: '50%',
            zIndex: 2,
            transform: 'translateX(-50%)',
        },
    },
}))

export const MenuLinkDotStyled = styled('div')(() => ({
    content: '" "',
    width: '4px',
    height: '4px',
    borderRadius: '4px',
    backgroundColor: '#f0f1f1',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: '24px',
}))

export const MenuArrowSeparatorStyle = styled(Box)(({theme}) => ({
    display: 'block',
    textAlign: 'center',
    border: '1px solid #f0f1f170',
    width: '100%',
    height: 0,
    position: 'relative',
}))
