import {styled} from "@mui/material";
import {AlgorithmType} from "../../model/types";


export const CalendarWeekStyle = styled('div')(({theme}) => ({
    ...theme.typography.body1,
    color: '#f0f1f1',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTop: '1px solid #f0f1f150',
    borderBottom: '1px solid #f0f1f150',
}))

export const CalendarDayStyle = styled('div')<{ disabled?: boolean }>(({theme, disabled}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    borderRight: '1px solid #f0f1f150',
    borderBottom: '1px solid #f0f1f150',
    color: '#f0f1f170',

    '&:nth-child(7n + 1)': {
        borderLeft: '1px solid #f0f1f150',
    },

    ...(disabled ? {
        background: 'repeating-linear-gradient(-45deg, #f0f1f120, #f0f1f120 10px, #646b73 10px,#646b73 20px)',
        cursor: 'default',
    } : {
        cursor: 'pointer',
        '&:hover': {
            color: '#f0f1f1',
        },
    })
}))

export const CalendarDayNumberStyle = styled('div')(({theme}) => ({
    textAlign: 'right',
}))

export const CalendarDayAlgListStyled = styled('div')(({theme}) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
}))

export const CalendarDayAlgListItemStyled = styled('div')<{ type: string }>(({theme, type}) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    width: '80%',
    maxWidth: '80%',
    gap: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(2),

    ...({
        [AlgorithmType.vqe]: {
            backgroundColor: '#e5eeff',
            color: '#646b73',
        },
        [AlgorithmType.molGeo]: {
            backgroundColor: '#ffefe7',
            color: '#646b73',
        },
        [AlgorithmType.reactions]: {
            backgroundColor: '#d2d4ff',
            color: '#646b73',
        },
    }[type])
}))