import {Box, Tab} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import React from "react";
import {VqeAdaptiveTabPanel} from "./VqeAdaptiveTabPanel";

export const AlgorithmTab = () => {
    const [tabSelected, setTabSelected] = React.useState("vqe");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabSelected(newValue);
    };

    return (
        <TabContext value={tabSelected}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleTabChange} scrollButtons={true} variant="scrollable"
                >
                    <Tab label="VQE Основное состояние молекулы" value="vqe"/>
                    <Tab label="Оптимизация геометрии молекулы" value="molGeo"/>
                    <Tab label="Моделирование химических реакций" value="reactions"/>
                    <Tab label="Моделирование химических реакций 2" value="reactions-2"/>
                </TabList>
            </Box>
            <TabPanel value="vqe" sx={{pl: 0}}>
                <VqeAdaptiveTabPanel/>
            </TabPanel>
            <TabPanel value="molGeo">molGeo</TabPanel>
            <TabPanel value="reactions">reactions</TabPanel>
            <TabPanel value="reactions-2">reactions-2</TabPanel>
        </TabContext>
    );
};
