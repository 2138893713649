import {styled} from "@mui/material";
import {AlgorithmType} from "../../model/types";

export const DayPopoverListItemStyle = styled('div')<{ type: AlgorithmType }>(({ theme, type }) => ({
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
    cursor: 'pointer',

    ...({
        [AlgorithmType.vqe]: {
            backgroundColor: '#e5eeff',
            color: '#646b73',
        },
        [AlgorithmType.molGeo]: {
            backgroundColor: '#ffefe7',
            color: '#646b73',
        },
        [AlgorithmType.reactions]: {
            backgroundColor: '#d2d4ff',
            color: '#646b73',
        },
    }[type])
}))