import {AlgorithmDayItem, AlgorithmType} from "../model/types";
import {CalendarDayAlgListItemStyled, CalendarDayAlgListStyled} from "./style/CalendarStyle";
import {Typography} from "@mui/material";
import React from "react";
import {getAlgorithmListMock} from "../api/getAlgorithmMock";

interface Props {
    day: number;
}

type AlgorithmDayItemGrouped = {
    [key: string]: {
        count: number;
    }
}

export const CalendarDayAlgList = ({day}: Props) => {
    const algorithmList = getAlgorithmListMock(day)
        .reduce((grouped: AlgorithmDayItemGrouped, item: AlgorithmDayItem) => {
            if (!grouped[item.type]) {
                grouped[item.type] = {
                    count: 0,
                };
            }
            grouped[item.type].count += 1;

            return grouped;
        }, {})

    return (
        <CalendarDayAlgListStyled>
            {Object.entries(algorithmList).map(([type, {count}]) => (
                <CalendarDayAlgListItemStyled key={type} type={type}>
                    <Typography variant="caption"
                                sx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                        {{
                            [AlgorithmType.vqe]: 'VQE - Основное состояние молекулы:',
                            [AlgorithmType.molGeo]: 'Оптимизация геометрии молекулы',
                            [AlgorithmType.reactions]: 'Моделирование химических реакций',
                        }[type]}
                    </Typography>
                    {count > 1 && <Typography variant="caption" fontWeight="bold">({count})</Typography>}
                </CalendarDayAlgListItemStyled>
            ))}
        </CalendarDayAlgListStyled>
    );
};
