import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface HistoryPageState {
    algUuidOpen: string | null;
    dayListOpen: number;
    dayListRect: DOMRect | null;
}

const initialState = {
    algUuidOpen: null,
    dayListOpen: 0,
    dayListRect: null,
} satisfies HistoryPageState as HistoryPageState

const historyPageSlice = createSlice({
    name: 'historyPageSlice',
    initialState,
    reducers: {
        openAlgBackdrop(state, action: PayloadAction<string>) {
            state.algUuidOpen = action.payload;
        },
        closeAlgBackdrop(state) {
            state.algUuidOpen = null;
        },
        openDayList(state, action: PayloadAction<{ day: number, rect: DOMRect }>) {
            state.dayListOpen = action.payload.day;
            state.dayListRect = action.payload.rect;
        },
        closeDayList(state) {
            state.dayListRect = null;
            state.dayListOpen = 0;
        },
    }
})

export const {
    closeAlgBackdrop,
    closeDayList,
    openAlgBackdrop,
    openDayList,
} = historyPageSlice.actions

export const historyPageName = historyPageSlice.name;
export const historyPageReducer = historyPageSlice.reducer;
