import {Typography} from "@mui/material";
import React from "react";
import {CardButton, CardStyled, CardTitleStyled} from "shared/ui/card";

export const Widget = () => {
    const handleAdditionalInfoClick = () => {
    }

    return (
        <CardStyled sx={{
            backgroundColor: "#f0f1f1",
        }}>
            <CardTitleStyled sx={{mb: 2}}>
                Документация
            </CardTitleStyled>
            <Typography sx={{mb: 2}}>
                Сервис предназначен для запуска квантовых алгоритмов.
                Выберите алгоритм, настройте параметры и алгоритм
                будет помещен в очередь на обработку<br />
            </Typography>
            <CardButton name="Подбробнее" onClick={handleAdditionalInfoClick}/>
        </CardStyled>
    );
};
