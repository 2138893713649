import {Box, IconButton, Menu, Slide, Typography, useTheme} from "@mui/material";
import {AppLogo} from "app/routes/ui/AppLogo";
import React, {useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import {RoutesPath} from "app/routes/const/routesPath";
import {NavLink} from "react-router";
import AppNotification from "app/routes/ui/AppNotification";

const AppMobileMenu = () => {
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: '#f0f1f1',
            pb: 2,
        }}>
            <AppLogo/>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
            }}>
                <AppNotification/>
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MenuIcon sx={{color: '#f0f1f1',}}/>
                </IconButton>
                <Menu
                    id="long-menu"
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                background: 'transparent',
                                boxShadow: 'none',
                            },
                        }
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {[
                        {path: RoutesPath.chemistry, name: 'Алгоритмы'},
                        {path: RoutesPath.chemistryHistory, name: 'История расчетов'},
                        {path: RoutesPath.doc, name: 'Документация'}
                    ].map(({path, name}, index) => (
                        <NavLink key={path} to={path} end style={{textDecoration: 'none'}}>
                            <Slide direction="left" in mountOnEnter unmountOnExit timeout={{ enter: index * 100 + 300 }}>
                                <Box sx={{
                                    pb: 2,
                                }}>
                                    <Box sx={{
                                        borderRadius: 5,
                                        backgroundColor: '#f4ebe5',
                                        color: '#081225',
                                        py: 1,
                                        px: 2,
                                    }}>
                                        {name}
                                    </Box>
                                </Box>
                            </Slide>
                        </NavLink>
                    ))}

                    <Slide direction="left" in mountOnEnter unmountOnExit timeout={{ enter: 400 }}>
                        <Box sx={{
                            display: 'flex',
                            borderRadius: 5,
                            backgroundColor: theme.palette.primary.light,

                            py: .5,
                            px: 1,
                            width: '200px',
                            gap: 1,
                        }}>
                            <Box sx={{
                                borderRadius: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <img src="/avatar.jpg" width="32px" height="32px" style={{borderRadius: '100%'}}/>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <Typography variant="caption" fontWeight="bold">Гущин Яков</Typography>
                                <Typography variant="caption">Отец-основатель</Typography>
                            </Box>
                        </Box>
                    </Slide>

                </Menu>
            </Box>
        </Box>
    );
};

export default AppMobileMenu;