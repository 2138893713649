import {Box, SwipeableDrawer, Typography, useTheme} from "@mui/material";
import React, {useRef, useState} from "react";
import {WidgetExecutionQueue} from "widgets/execution-queue";

// TODO: add to theme mixin
const drawerBleeding = 56;

export const ExecutionQueueDrawer = () => {
    const theme = useTheme();

    const ref = useRef();
    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <Box
            ref={ref}
            sx={{
                display: 'none',
                [theme.breakpoints.down('lg')]: {
                    display: 'block',
                }
            }}>
            <SwipeableDrawer
                container={ref.current}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                PaperProps={{
                    sx: {
                        background: '#081225',
                    },
                }}
                SwipeAreaProps={{
                    onClick: toggleDrawer(true),
                }}

            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        position: 'absolute',
                        zIndex: 1200,
                        top: '-56px',
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        pt: 2,
                        backgroundColor: '#081225',
                        borderTopLeftRadius: theme.spacing(3),
                        borderTopRightRadius: theme.spacing(3),
                    }}
                >
                    <Box
                        sx={{
                            width: 30,
                            height: 6,
                            backgroundColor: '#f0f1f190',
                            borderRadius: 3,
                        }}
                    />
                    <Typography variant="subtitle1" fontWeight="bold" sx={{color: '#f0f1f190'}}>
                        Алгоритмы в очереди выполнения
                    </Typography>
                    <WidgetExecutionQueue disableTitle />
                </Box>
            </SwipeableDrawer>
        </Box>

    );
};
