import {historyPageName, HistoryPageState} from "./slice";
import {createSelector} from "@reduxjs/toolkit";

type RootState = {
    [historyPageName]: HistoryPageState
}

const selectSelf = (state: RootState) => state[historyPageName];


export const algUuidOpenSelector = createSelector(selectSelf, (state) => state.algUuidOpen)

export const dayListIsOpenSelector = createSelector(selectSelf, (state) => state.dayListOpen)

export const dayListRectSelector = createSelector(selectSelf, (state) => state.dayListRect)