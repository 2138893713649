import {Box, Popover, Typography} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React, {useEffect, useState} from "react";
import {dayListIsOpenSelector, dayListRectSelector} from "../model/selectors";
import {useDispatch, useSelector} from "react-redux";
import {PopoverProps} from "@mui/material/Popover/Popover";
import {closeDayList, openAlgBackdrop} from "../model/slice";
import {getAlgorithmListMock} from "../api/getAlgorithmMock";
import {DayPopoverListItemStyle} from "./style/DayPopoverStyle";
import {AlgorithmType} from "../model/types";
import dayjs from "dayjs";

interface Props {
}

const DayPopover = () => {
    const dispatch = useDispatch();

    const day = useSelector(dayListIsOpenSelector)
    const rect = useSelector(dayListRectSelector)

    const [isOpen, setOpen] = useState(!!day);

    const anchorEl: PopoverProps['anchorEl'] = rect
        ? {getBoundingClientRect: () => rect, nodeType: 1}
        : null

    const handlePopoverClose = (e: any) => {
        e.stopPropagation();
        setOpen(false)
        setTimeout(() => {
            dispatch(closeDayList())
        }, 300)
    };

    const handleBackdropOpen = (e: any) => {
        // setTimeout(() => {
        dispatch(closeDayList())
        dispatch(openAlgBackdrop('das'))
        // }, 300)
        // e.stopPropagation()
        // TODO: Добавить открытие поповера на закрытия backdrop
        // handlePopoverClose(e)
        // setBackdropOpen(true)
    }

    useEffect(() => {
        console.log('useEffect', day)
        setOpen(!!day)
    }, [day])


    return (
        <Popover
            id={isOpen ? `history-page-day-list-popover` : undefined}
            open={isOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            sx={{
                // pointerEvents: 'none',
                '.MuiPaper-root': {
                    // zIndex: 1000000,
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                },
            }}

        >
            <Box
                sx={{px: 2}}
                onClick={(e) => e.stopPropagation()}
            >
                <Typography variant="h6"
                            sx={{color: '#f0f1f1', mb: 3, textAlign: 'center'}}>{day} Ноября</Typography>
                <Box
                    onScroll={e => {
                        console.log(e.currentTarget.scrollHeight,e.currentTarget.offsetHeight)
                        if (e.currentTarget.offsetHeight + e.currentTarget.scrollTop + 20 >= e.currentTarget.scrollHeight) {
                            console.log('end')
                            e.currentTarget.classList.add("end-of-line");
                        } else if (e.currentTarget.classList.contains("end-of-line")) {
                            e.currentTarget.classList.toggle("end-of-line");
                            console.log('not end');
                        }
                    }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: 400,
                        overflow: 'auto',
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'sticky',
                            width: '100%',
                            bottom: '1px',
                            zIndex: 1,
                            boxShadow: '0px 0px 8px 2px #000000',

                        },
                        '&.end-of-line:after': {
                            boxShadow: 'none',
                        },
                        '& > *:not(:last-of-type)': {
                            mb: 2,
                        }
                    }}>
                    {getAlgorithmListMock(day).map(({ type,timeFinished}, index) => (
                        <DayPopoverListItemStyle key={index} type={type} onClick={handleBackdropOpen}>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {{
                                        [AlgorithmType.vqe]: 'VQE - Основное состояние молекулы:',
                                        [AlgorithmType.molGeo]: 'Оптимизация геометрии молекулы',
                                        [AlgorithmType.reactions]: 'Моделирование химических реакций',
                                    }[type]}
                                </Typography>
                                <Typography variant="caption">
                                    Выполнен в {dayjs(timeFinished).format('HH:mm')}
                                </Typography>
                            </Box>
                            <ArrowRightAltIcon/>

                        </DayPopoverListItemStyle>
                    ))}
                </Box>
            </Box>

        </Popover>
    );
};

export default DayPopover;