import DayPopover from "./DayPopover";
import React from "react";
import {Box} from "@mui/material";
import {Header} from "./Header";
import {Calendar} from "./Calendar";
import {DayBackdrop} from "./DayBackdrop";

export const Page = () => {
    console.log('Page render')
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
            }}>
                <Header/>
                <Calendar/>
            </Box>
            <DayPopover/>
            <DayBackdrop/>
        </>
    );
};

