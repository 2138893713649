import React from "react";

import {Box, useTheme} from "@mui/material";
import {Outlet} from "react-router";
import {LayoutWrapperStyle} from "./style/LayoutStyle";
import {AppDesktopMenu} from "app/routes/ui/responsive/desktop/AppDesktopMenu";
import AppMobileMenu from "app/routes/ui/responsive/mobile/AppMobileMenu";
// import { HighlightedCode } from '@mui/docs/HighlightedCode';


export const AppLayout = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            p: 5,
            pt: 3,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            [theme.breakpoints.down('md')]: {
                p: 3,
            }
        }}>
            <Box>
                <Box sx={{
                    borderBottom: '1px solid #f0f1f170',
                }}>
                    {/*<LayoutWrapperStyle sx={{*/}
                    {/*    display: 'flex',*/}
                    {/*    color: '#f0f1f1',*/}
                    {/*}}>*/}
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        alignItems: 'flex-start',*/}
                    {/*        mr: '200px',*/}
                    {/*        gap: 1,*/}
                    {/*    }}>*/}
                    {/*        <Box><img src="/logo-light.png" width="100%" height="32px"/></Box>*/}
                    {/*        <Typography fontWeight="bold" variant="subtitle1">Quantopus</Typography>*/}
                    {/*    </Box>*/}
                    <Box sx={{
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        }
                    }}>
                        <AppDesktopMenu/>
                    </Box>
                    <Box sx={{
                        [theme.breakpoints.up('md')]: {
                            display: 'none',
                        }
                    }}>
                        <AppMobileMenu/>
                    </Box>
                    {/*    <Box sx={{*/}
                    {/*        display: 'flex',*/}
                    {/*        gap: 3,*/}
                    {/*        pb: 2,*/}
                    {/*    }}>*/}
                    {/*        <AppNotification />*/}
                    {/*        <AppUser />*/}
                    {/*    </Box>*/}
                    {/*</LayoutWrapperStyle>*/}
                </Box>
            </Box>
            <LayoutWrapperStyle>
                <Outlet/>
            </LayoutWrapperStyle>
        </Box>
    );
};
