import {AlgorithmType} from "../model/types";

const vqe = {
    uuid: 'ba1ce1bd-b9ce-4bc8-a36c-cf2380a56dba',
    type: AlgorithmType.vqe,
    timeFinished: new Date(),
}

const molGeo = {
    uuid: 'ba1ce1bd-b9ce-4bc8-a36c-cf2380a56dba',
    type: AlgorithmType.molGeo,
    timeFinished: new Date(),
}

const reactions = {
    uuid: 'ba1ce1bd-b9ce-4bc8-a36c-cf2380a56dba',
    type: AlgorithmType.reactions,
    timeFinished: new Date(),
}

export const getAlgorithmListMock = (day: number) => {
    switch (day) {
        case 2:
            return [vqe, vqe, molGeo, reactions, vqe, vqe, molGeo]
        case 5:
            return [molGeo, reactions, molGeo]
        case 6:
            return [molGeo, vqe, vqe, vqe, molGeo]
        case 22:
            return [reactions, reactions, reactions, molGeo, vqe, vqe, vqe, molGeo, reactions]
        case 23:
            return [vqe]
        case 24:
            return [vqe, reactions, vqe, vqe, reactions]
    }
    return [
    ]
}