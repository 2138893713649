import {Alert, AlertTitle, Box, CircularProgress, IconButton, Typography, useTheme} from "@mui/material";
import {CardStyled, CardTitleStyled} from "shared/ui/card";
import React, {useEffect, useState} from "react";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {useGetAlgorithmListQuery} from "shared/api";
import {QuantumChemistryAlgorithmType} from "shared/models";
import {LoadingAnimationStyled} from "../style/LoadingStyle";
import RefreshIcon from '@mui/icons-material/Refresh';
import {useSnackbar} from "notistack";


export const WidgetHistory = () => {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar();

    const [selectedAlgUuid, setSelectedTabUuid] = useState<string | null>(null)

    const {data: algorithmList, isSuccess, isLoading, isUninitialized, refetch} = useGetAlgorithmListQuery({
        type: QuantumChemistryAlgorithmType.vqe,
        status: 'complete',
        size: 10,
    })
    // console.log(data)
    // const algorithmList = getLastComputeVqeAlgorithms();
    const selectedAlgorithm = algorithmList?.find(({uuid}) => uuid === selectedAlgUuid);

    const handleTabChanged = (_: React.MouseEvent<HTMLElement>, newAlgUuid: string | null) => {
        setSelectedTabUuid(newAlgUuid)
    }

    const handleRefresh = () => {
        refetch()
            .unwrap()
            .then(() => {
                enqueueSnackbar('История успешно синхронизирована', {variant: 'success'})
            })
            .catch(() => {
                enqueueSnackbar('Ошибка синхронизации истории', {variant: 'error'})
            });
    }

    useEffect(() => {
        if (algorithmList?.length) {
            setSelectedTabUuid(algorithmList[0].uuid)
        }
    }, [algorithmList?.length])

    return (
        <CardStyled sx={{
            backgroundColor: '#e5eeff',
            width: '100%',
        }}>

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                mb: 3,
            }}>
                <CardTitleStyled>
                    Последние завершенные алгоритмы
                </CardTitleStyled>
                <IconButton onClick={handleRefresh}>
                    <RefreshIcon/>
                </IconButton>
            </Box>

            {!!algorithmList?.length && <ToggleButtonGroup
                color="primary"
                value={selectedAlgUuid}
                size="small"
                exclusive
                onChange={handleTabChanged}
                sx={{
                    mb: 3,
                    width: 'fit-content',
                    maxWidth: '100%',
                    overflow: 'hidden',
                }}
            >
                {algorithmList.map(({uuid, params}) => (
                    <ToggleButton key={uuid} value={uuid}>{params.molName} / {params.basis}</ToggleButton>
                ))}
            </ToggleButtonGroup>}

            {(selectedAlgorithm) && (
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 3,
                    }}>
                        {selectedAlgorithm?.error && (
                            <Alert severity="error" sx={{borderRadius: 3, mb: 3}}>
                                <AlertTitle>Ошибка при выполнении алгоритма</AlertTitle>
                                {selectedAlgorithm.error}
                            </Alert>
                        )}
                        {selectedAlgorithm?.result && (
                            <Box sx={{
                                borderBottom: '1px solid #d1d3d6',
                                pb: 3,
                                mb: 3,
                            }}>
                                <Typography variant="h3"
                                            sx={{textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '70%'}}>
                                    {selectedAlgorithm.result.energy.value}
                                </Typography>
                                <Typography variant="body2">
                                    Энергия основного состояния
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: 2, rowGap: 1}}>
                            <Box>
                                <Typography variant="h6">
                                    {selectedAlgorithm?.params.molName}
                                </Typography>
                                <Typography variant="body2">
                                    Молекула
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    {selectedAlgorithm?.params.basis}
                                </Typography>
                                <Typography variant="body2">
                                    Базис
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    {selectedAlgorithm?.params.accuracy.toExponential(0)}
                                </Typography>
                                <Typography variant="body2">
                                    Требуемая точность
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    {selectedAlgorithm?.params.activeElectrons}
                                </Typography>
                                <Typography variant="body2">
                                    Кол-во активных электронов
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="h6">
                                    {selectedAlgorithm?.params.activeOrbitals}
                                </Typography>
                                <Typography variant="body2">
                                    Кол-во активных орбиталей
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {selectedAlgorithm?.result && (
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Схема квантового алгоритма
                            </Typography>
                            <Box component="pre" sx={{
                                fontSize: theme.typography.h6.fontSize,
                                overflow: 'auto',
                                height: '150px',
                                m: 0,
                                flexGrow: 1,
                                maxWidth: 'stretch',
                                // maxWidth: 'calc(100vw - 132px)',
                            }}>
                                {selectedAlgorithm.result.quantumScheme}
                            </Box>
                            {/*<Typography fontSize="caption" color="textDisabled" sx={{*/}
                            {/*    display: 'flex',*/}
                            {/*    alignItems: 'center',*/}
                            {/*    justifyContent: 'center',*/}
                            {/*    gap: 1,*/}
                            {/*    transform: 'scale(-1, -1) rotate(90deg)',*/}
                            {/*    width: 0*/}
                            {/*}}>*/}
                            {/*    <ArrowRightAltIcon sx={{transform: 'rotate(180deg)'}}/>*/}
                            {/*    scroll*/}
                            {/*    <ArrowRightAltIcon/>*/}
                            {/*</Typography>*/}
                            {/*<Typography fontSize="caption" color="textDisabled"*/}
                            {/*            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1}}>*/}
                            {/*    <ArrowRightAltIcon sx={{transform: 'rotate(180deg)'}}/>*/}
                            {/*    scroll*/}
                            {/*    <ArrowRightAltIcon/>*/}
                            {/*</Typography>*/}
                        </Box>
                    )}
                </>
            )}
            {(!selectedAlgorithm && isSuccess) && (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    flexGrow: 1,
                    gap: 3
                }}>
                    <LoadingAnimationStyled>
                        <img src="/empty.png" height="300px"/>
                    </LoadingAnimationStyled>
                    <Typography variant="h6">Здесь будет история расчетов</Typography>
                </Box>
            )}
            {(isLoading || isUninitialized) && (
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1}}>
                    <svg width={0} height={0}>
                        <defs>
                            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" stopColor="#081225"/>
                                <stop offset="100%" stopColor="#646b73"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <CircularProgress sx={{'svg circle': {stroke: 'url(#my_gradient)'}}}/>
                </Box>
            )}
        </CardStyled>
    );
};
