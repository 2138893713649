import {Box, Button, Chip, CircularProgress, Divider, Typography, useTheme} from "@mui/material";
import PendingIcon from "@mui/icons-material/Pending";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, {useEffect, useState} from "react";
import {CardStyled, CardTitleStyled} from "shared/ui/card";
import {LoadingAnimationStyled} from "./style/LoadingAnimationStyle";
import TagIcon from '@mui/icons-material/Tag';
import {useGetAlgorithmListQuery} from "shared/api";
import {
    QuantumChemistryAlgorithmListResponse,
    QuantumChemistryAlgorithmResultStatus,
    QuantumChemistryAlgorithmType
} from "shared/models";
import {TaskBodyStyled, TaskWrapperStyled} from "widgets/execution-queue/ui/style/TaskStyle";

interface Props {
    disableTitle?: boolean;
}

export const Widget = ({ disableTitle }: Props) => {
    const theme = useTheme();
    const [pollingInterval, setPollingInterval] = useState(0);
    const {data, isUninitialized, isFetching, isSuccess} = useGetAlgorithmListQuery({
        type: QuantumChemistryAlgorithmType.vqe,
        status: ['running', 'waiting'],
        size: 10,
    }, {
        pollingInterval,
    })

    let taskGroupList = data?.reduce((result: { [key in QuantumChemistryAlgorithmResultStatus]?: QuantumChemistryAlgorithmListResponse }, item) => {
        if (!result[item.status]) {
            result[item.status] = []
        }
        result[item.status]?.push(item)

        return result
    }, {})

    const hasTasks = !!taskGroupList?.[QuantumChemistryAlgorithmResultStatus.running]
        || !!taskGroupList?.[QuantumChemistryAlgorithmResultStatus.waiting]

    useEffect(() => {
        if (hasTasks) {
            setPollingInterval(5000)
        } else {
            setPollingInterval(0)
        }
    }, [hasTasks])

    return (
        <CardStyled sx={{backgroundColor: '#081225'}}>
            {!disableTitle && <CardTitleStyled sx={{color: '#f0f1f1', mb: 5}}>
                Алгоритмы в очереди выполнения
            </CardTitleStyled>}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                mb: 1,
                height: '100%',
                overflow: 'auto',
                '.MuiButton-root:hover': {
                    backgroundColor: 'rgba(8, 18, 37, 0.24)',
                }
            }}>
                {(hasTasks && isSuccess) && (
                    <>
                        {taskGroupList?.[QuantumChemistryAlgorithmResultStatus.running]?.map(task => (
                            <TaskWrapperStyled key={task.uuid} type="running">
                                <TaskBodyStyled>
                                    <LoadingAnimationStyled>
                                        <PendingIcon/>
                                    </LoadingAnimationStyled>
                                    <Box>
                                        <Typography fontWeight="bold" sx={{mb: 0.5}}>
                                            VQE - Основное состояние молекулы:
                                        </Typography>
                                        <Typography>{task.params.molName} / {task.params.basis}</Typography>
                                    </Box>
                                </TaskBodyStyled>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button variant="text" size="small" sx={{color: '#f0f1f1'}}>
                                        Отменить
                                    </Button>
                                    <Button variant="text" size="small" sx={{color: '#e5eeff'}}>
                                        Подробнее
                                    </Button>
                                </Box>
                            </TaskWrapperStyled>
                        ))}
                        {!!taskGroupList?.[QuantumChemistryAlgorithmResultStatus.running]
                            && !!taskGroupList?.[QuantumChemistryAlgorithmResultStatus.waiting] && (
                                <Divider sx={{backgroundColor: '#f0f1f170'}}/>
                            )}
                        {taskGroupList?.[QuantumChemistryAlgorithmResultStatus.waiting]?.map(task => (
                            <TaskWrapperStyled key={task.uuid} type="waiting">
                                <TaskBodyStyled>
                                    <AccessTimeIcon/>
                                    <Box>
                                        <Typography fontWeight="bold" sx={{mb: 0.5}}>
                                            VQE: Основное состояние молекулы
                                        </Typography>
                                        <Typography>{task.params.molName} / {task.params.basis}</Typography>
                                    </Box>
                                </TaskBodyStyled>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Button size="small" variant="text" sx={{color: '#f0f1f1'}}>
                                        Отменить
                                    </Button>
                                    <Button size="small" variant="text" sx={{color: '#f0f1f1'}}>
                                        Подробнее
                                    </Button>
                                </Box>
                            </TaskWrapperStyled>
                        ))}
                    </>
                )}
                {(!isFetching && !isUninitialized && !hasTasks && isSuccess) && (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1
                    }}>
                        <Box
                            component="img"
                            src="/clock.png"
                            sx={{
                                width: "150px",
                                [theme.breakpoints.down('lg')]: {
                                    width: "100px"
                                }
                            }}
                        />
                        <Typography variant="subtitle1" fontWeight="bold"
                                    sx={{color: '#f0f1f1', textAlign: 'center', mt: 5}}>Здесь появятся рассчитываемые
                            алгоритмы</Typography>
                        <Box sx={{display: 'flex', gap: 2, mt: 3, justifyContent: 'center', flexWrap: 'wrap'}}>
                            <Chip icon={<TagIcon/>} size="small" label="Ожидают обработки"
                                  sx={{backgroundColor: '#fae0c1'}}/>
                            <Chip icon={<TagIcon/>} size="small" label="В процессе" sx={{backgroundColor: '#e5eeff'}}/>
                        </Box>
                    </Box>
                )}
                {(isFetching || isUninitialized) && !hasTasks && (
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1}}>
                        <svg width={0} height={0}>
                            <defs>
                                <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" stopColor="#e5eeff"/>
                                    <stop offset="100%" stopColor="#646b73"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <CircularProgress sx={{'svg circle': {stroke: 'url(#my_gradient)'}}}/>
                    </Box>
                )}
            </Box>

        </CardStyled>
    );
};
