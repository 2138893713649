import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    QuantumChemistryAlgorithmListRequest,
    QuantumChemistryAlgorithmListResponse,
    RunQuantumChemistryAlgorithmRequest,
    RunQuantumChemistryAlgorithmResponse
} from "../models";

export const quantumChemistryRunAlgorithmApi = createApi({
    reducerPath: 'quantumChemistryRunAlgorithmApi',
    baseQuery: fetchBaseQuery({baseUrl: 'api/algorithm/quantum-chemistry'}),
    tagTypes: ['QuantumChemistryAlgorithmList'],
    endpoints: (builder) => ({
        runAlgorithm: builder.mutation<RunQuantumChemistryAlgorithmResponse, RunQuantumChemistryAlgorithmRequest>({
            query: (body) => ({
                url: 'run',
                method: 'POST',
                body,
            }),
            invalidatesTags: ['QuantumChemistryAlgorithmList'],
        }),
        getAlgorithmList: builder.query<QuantumChemistryAlgorithmListResponse, QuantumChemistryAlgorithmListRequest>({
            query: (params) => ({
                url: 'list',
                method: 'GET',
                params,
            }),
            providesTags: ['QuantumChemistryAlgorithmList'],
        })
    }),
})

export const {
    useRunAlgorithmMutation,
    useGetAlgorithmListQuery,
} = quantumChemistryRunAlgorithmApi;