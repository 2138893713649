import {Badge, Box} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import React from "react";

const AppNotification = () => {
    return (
        <Box sx={{
            backgroundColor: '#6f777f',
            borderRadius: '100%',
            width: '48px',
            height: '48px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Badge badgeContent=" " color="error" sx={{'.MuiBadge-badge': {backgroundColor: '#ffefe7'}}}
                   variant="dot">
                <NotificationsNoneIcon/>
            </Badge>
        </Box>
    );
};

export default AppNotification;