import {Box, Typography} from "@mui/material";
import React from "react";

export const AppLogo = () => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 1,
        }}>
            <Box><img src="/logo-light.png" width="32px" height="32px"/></Box>
            <Typography fontWeight="bold" variant="subtitle1">Quantopus</Typography>
        </Box>
    );
};
