import {Box, FormControl, MenuItem, Select, Typography, useTheme} from "@mui/material";
import React from "react";

export const Header = () => {
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 3,
            [theme.breakpoints.down('md')]: {
                alignItems: 'flex-start',
                flexDirection: 'column',
                gap: 2,
            }
        }}>
            <Box>
                <Typography variant="h5" sx={{color: '#f0f1f1'}}>Запуск квантовых алгоритмов</Typography>
                <Typography sx={{color: '#f0f1f190'}}>
                    Настройте параметры модели и поместите задачу в очередь
                </Typography>
            </Box>
            <Box>
                <FormControl sx={{mr: 3, minWidth: 120}} size="small">
                    <Select value="chemistry">
                        <MenuItem value="chemistry">Алгоритмы квантовой химии</MenuItem>
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
};
