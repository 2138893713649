import {styled} from "@mui/material";

export const TaskWrapperStyled = styled('div')<{ type: 'waiting' | 'running' }>(({theme, type}) => ({
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    ...({
        'waiting': {
            backgroundColor: '#3d4452',
            color: '#f0f1f1',
        },
        'running': {
            backgroundColor: '#3d4452',
            color: '#e5eeff',
        },
    }[type])
}))

export const TaskBodyStyled = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    position: 'relative',
}))