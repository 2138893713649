import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {AppStore} from "./store";

import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {baseThemeOptions} from "./theme";

// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
//
// import '@fontsource/rubik/300.css';
// import '@fontsource/rubik/400.css';
// import '@fontsource/rubik/500.css';
// import '@fontsource/rubik/700.css';

import '@fontsource/ibm-plex-sans/300.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/700.css';

// import '@fontsource/nunito/300.css';
// import '@fontsource/nunito/400.css';
// import '@fontsource/nunito/500.css';
// import '@fontsource/nunito/700.css';

import './global.css'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {SnackbarProvider} from "notistack";
import {BrowserRouter, Route, Routes} from "react-router";
import {AppLayout, RoutesPath} from "app/routes";
import {MainPage} from "pages/main";
import {HistoryPage} from "pages/history";
import Global from "@mui/styled-engine-sc/GlobalStyles";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// TODO: add to theme mixin
const drawerBleeding = 56;

const appTheme = createTheme(baseThemeOptions)

root.render(
    <React.StrictMode>
        <Provider store={AppStore}>
            <Suspense fallback={<div></div>}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={appTheme}>
                        <CssBaseline enableColorScheme/>
                        <Global
                            styles={{
                                '.MuiDrawer-root > .MuiPaper-root': {
                                    height: `calc(50% - ${drawerBleeding}px)`,
                                    overflow: 'visible',
                                },
                            }}
                        />
                        <SnackbarProvider maxSnack={3}>
                            <BrowserRouter>
                                <Routes>
                                    <Route element={<AppLayout/>}>
                                        <Route index path={RoutesPath.chemistry} element={<MainPage/>}/>
                                        <Route path={RoutesPath.chemistryHistory} element={<HistoryPage/>}/>
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </SnackbarProvider>
                    </ThemeProvider>
                </LocalizationProvider>
            </Suspense>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
