import {configureStore} from '@reduxjs/toolkit'
import {chemistryApi} from "./pages/chemistry";
import {setupListeners} from "@reduxjs/toolkit/query";
import {historyPageName, historyPageReducer} from "pages/history";
import {quantumChemistryRunAlgorithmApi} from "shared/api";

export const AppStore = configureStore({
    reducer: {
        // api
        [chemistryApi.reducerPath]: chemistryApi.reducer,
        [quantumChemistryRunAlgorithmApi.reducerPath]: quantumChemistryRunAlgorithmApi.reducer,
        // slices
        [historyPageName]: historyPageReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            chemistryApi.middleware,
            quantumChemistryRunAlgorithmApi.middleware,
        ),
})

setupListeners(AppStore.dispatch)


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof AppStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof AppStore.dispatch