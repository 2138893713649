import {keyframes, styled} from 'styled-components'

const loadingAnimation = keyframes`
  100% {
    transform: rotate(360deg)
  }
`

export const LoadingAnimationStyled = styled('div')`
  animation: 2s ${loadingAnimation} linear;
  animation-iteration-count: infinite;
  transform-origin: center center;
  width: 24px;
  height: 24px;
`
