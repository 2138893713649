import {Box, styled} from "@mui/material";
import {AlgorithmType} from "../../model/types";

export const DayBackdropCardStyle = styled(Box)<{ color: 'primary' | 'white' }>(({theme, color}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,

    ...({
        'primary': {
            backgroundColor: theme.palette.primary.main,
            color: '#f0f1f1',
        },
        'white': {
            backgroundColor: '#f0f1f1',
            color: theme.palette.primary.main,
        },
    }[color as 'primary' | 'white'])
}))

export const DayBackdropCardTitleStyled = styled('div')(({theme}) => ({
    ...theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(3),
}))