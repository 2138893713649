import {Box, Button, styled} from "@mui/material";

export const CardStyled = styled(Box)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    height: '100%',
}))

export const CardTitleStyled = styled('div')(({theme}) => ({
    ...theme.typography.subtitle1,
    fontWeight: theme.typography.fontWeightBold,
}))

export const CardParamListStyled = styled('ul')(({theme}) => ({
    paddingLeft: theme.spacing(3)
}))

export const CardParamStyled = styled('li')(() => ({}))

export const CardButtonStyled = styled(Button)(() => ({}))